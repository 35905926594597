<template>
    <v-container class="pa-0" fluid>
        <page-title :heading="$t('erp.lang_Allergene') "
                    :subheading="$t('erp.lang_EditAllergen')"
                    :icon="icon"
                    show-previous-button
        ></page-title>
        <div class="app-main__inner">
            <allergens-edit-component/>
        </div>
    </v-container>
</template>

<script>
    import PageTitle from "../../../../Layout/Components/PageTitle";
    import AllergensEditComponent from "../../../../components/erp/settings/allergen/AllergensEditComponent";
    export default {
        name: "AllergensEdit",
        components: {AllergensEditComponent, PageTitle},
        data: () => ({
            icon: 'pe-7s-tools icon-gradient bg-tempting-azure',
        }),
    }
</script>

<style scoped>

</style>