<template>
    <v-container fluid>
        <v-card elevation="0" outlined>
            <v-card-title class="headline">
                {{$t('erp.lang_EditAllergen')}}
            </v-card-title>
            <v-divider class="pa-0 ma-0"/>
            <div style="text-align: center;" v-if="loading">
                <v-progress-circular color="primary" indeterminate size="50"></v-progress-circular>
            </div>
            <v-card-text v-if="!loading">
                <v-layout>
                    <v-flex md12>
                        <div>
                            <v-layout>
                                <v-flex md12>
                                    <v-text-field outlined
                                                  @focus="showTouchKeyboard"
                                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                  v-model="text"
                                                  :label="$t('erp.lang_Allergene')"
                                                  autocomplete="off"
                                                  required
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>

                            <v-layout>
                                <v-flex class="text-right">
                                    <v-btn color="error"
                                           :loading="loading_delete"
                                           @click="deleteData">
                                        {{ $t('generic.lang_delete')}}
                                    </v-btn>
                                    <v-btn color="success" :loading="loading_update" :disabled="text.length < 1" @click="updateData">
                                        {{ $t('generic.lang_edit') }}
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                        </div>
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>

        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded" :options="touchKeyboard.options"
                                :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                                :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                                :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
        </div>
    </v-container>
</template>

<script>
    import {ENDPOINTS} from '../../../../config'
    import {Events} from "../../../../plugins/events";
    import mixin from '../../../../mixins/KeyboardMixIns';

    export default {
        name: "AllergensEditComponent",
        mixins:[mixin],
        data(){
            return{
                ENDPOINTS,
                loading:true,
                loading_update:false,
                loading_delete:false,
                id:null,
                text:'',
            }
        },
        methods:{
            getData(){
                let self=this;
                self.loading=true;
                this.axios.post(ENDPOINTS.ERP.ALLERGENE.GET,{
                    editAllergeneID:this.$route.params.id,
                }).then(res=>{
                    if (res.status === 200) {
                        self.id = this.$route.params.id;
                        self.text = res.data.formfillData.textFields.allergenName;
                    } else {

                        Events.$emit("showSnackbar", {
                            message: self.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                    self.loading=false;
                }).catch((err) => {

                    Events.$emit("showSnackbar", {
                        message: self.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                    self.loading=false;
                });
            },
            updateData: function () {
                let self = this;
                self.loading_update=true;
                this.axios.post(ENDPOINTS.ERP.ALLERGENE.UPDATE, {
                    editAllergeneID: this.id,
                    allergenName: this.text
                }).then((res) => {
                    if (res.data.status === 'SUCCESS') {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_actionSuccessful'),
                            color: "success"
                        });
                        this.$router.go(-1);
                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                    self.loading_update=false;
                }).catch(() => {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                    self.loading_update=false;
                });
            },
            deleteData: function (idsToDelete = []) {
                let self = this;

                this.$swal({
                    title: this.$t('erp.lang_deleteAllergen'),
                    text: this.$t('erp.lang_deleteAllergenText'),
                    cancelButtonText: this.$t('generic.lang_cancel'),
                    confirmButtonText: this.$t('generic.lang_delete'),
                    icon: "warning",
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
                            idsToDelete = [];
                            idsToDelete.push(parseInt(this.id));
                        }
                        self.loading_delete=true;
                        this.axios.post(ENDPOINTS.ERP.ALLERGENE.DELETE, {
                            editAllergeneID: idsToDelete
                        }).then((res) => {
                            if (res.data.status === 'SUCCESS') {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('erp.lang_allergensDeleted'),
                                    color: "success"
                                });
                                this.$router.go(-1);
                            } else {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('generic.lang_errorOccurred'),
                                    color: "error"
                                });
                            }
                            self.loading_delete=false;
                        }).catch(err=>{
                            Events.$emit("showSnackbar", {
                                message: this.$t('generic.lang_errorOccurred'),
                                color: "error"
                            });
                            self.loading_delete=false;
                        });
                    },
                    allowOutsideClick: () => !this.$swal.isLoading,
                    onClose:()=>self.loading_delete=false
                });
            },
        },
        mounted() {
            this.getData();
        }
    }
</script>

<style scoped>

</style>